import { FitEnum } from "sharp";

export const HOST =
  typeof process !== "undefined" && process.env.APP_HOST
    ? process.env.APP_HOST
    : typeof process !== "undefined" && process.env.NODE_ENV === "production"
    ? "https://teatro.app"
    : "http://localhost:5173";

export const ROUTES = {
  HOME: "/",
  LOGIN: "/login",
  SIGNUP: "/signup",
  SIGNUP_COMPLETE: "/signup/complete",
  LOGOUT: "/logout",
  DASHBOARD: "/dashboard",
  DASHBOARD_MY_RATINGS: "/dashboard/my-ratings",
  DASHBOARD_MY_PLAYS: "/dashboard/my-plays",
  PASSWORD_RECOVER: "/password-recover",
  PASSWORD_RECOVER_COMPLETE: "/password-recover/complete",
  RADAR: "/radar",
  ADD_NEW_PLAY: "/adicionar",
  ABOUT: "/about",
  CONTACT: "/talk-to-us",
  PRIVACY_POLICY: "/privacy",
  TERMS_AND_CONDITIONS: "/terms-and-conditions",
};

export const API_ROUTES = {
  LOCATION_SEARCH: "/api/location-search",
};

export const SIGNUP = {
  TOKEN_DURATION: 1000 * 60 * 60 * 24 * 7, // 7 days
};
export const PASSWORD_RECOVER = {
  TOKEN_DURATION: 1000 * 60 * 60 * 4, // 4 hours
};

export const RATING_SCALE = [
  { value: 1, label: "⭐️ 1", description: "Terrível" },
  { value: 2, label: "⭐️ 2", description: "Péssimo" },
  { value: 3, label: "⭐️ 3", description: "Muito mau" },
  { value: 4, label: "⭐️ 4", description: "Mau" },
  { value: 5, label: "⭐️ 5", description: "Fraquinho" },
  { value: 6, label: "⭐️ 6", description: "Decente" },
  { value: 7, label: "⭐️ 7", description: "Bom" },
  { value: 8, label: "⭐️ 8", description: "Muito Bom" },
  { value: 9, label: "⭐️ 9", description: "Excelente" },
  { value: 10, label: "⭐️ 10", description: "Incrível" },
];

export const PLAY_STATUS = {
  OK: "ok",
  USER_SUBMITTED: "user-submitted",
  PENDING: "pending",
  ERROR: "error",
  HIDDEN: "hidden",
  DRAFT: "draft",
};

export const PLAY_GENRES = [
  { id: "drama", lang_pt: "Drama" },
  { id: "comedy", lang_pt: "Comédia" },
  { id: "musical", lang_pt: "Musical" },
  { id: "children", lang_pt: "Infantil" },
  { id: "other", lang_pt: "Outro" },
];

export const MISSING_VENUE_ID = "164bbae8-a365-4770-959d-12d38bbe89d8";

export const IMAGE_OPTIMIZATION_PARAMS = {
  poster: {
    resize: {
      width: 1200,
      height: 640,
      fit: "outside" as keyof FitEnum,
    },
    quality: 80,
  },
  preview: {
    resize: {
      width: 500,
      height: 500,
      fit: "cover" as keyof FitEnum,
    },
    quality: 90,
  },
  venueCover: {
    resize: {
      width: 1200,
      height: 640,
      fit: "outside" as keyof FitEnum,
    },
    quality: 80,
  },
  playMedia: {
    thumb: {
      resize: {
        width: 200,
        height: 200,
      },
      quality: 60,
    },
    regular: {
      resize: {
        width: 1000,
        height: 1000,
      },
      quality: 80,
    },
  },
};

export const MEDIA_UPLOAD = {
  MAX_BODY_SIZE_IN_MB: 10,
};
